import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { APP_ENVIRONMENT, SessionService } from '../services/session.service';
import { AppEnvironment } from '../model/env';
import { FormControl, Validators } from '@angular/forms';
import { takeWhile } from 'rxjs/operators';
import { AcceptService } from '../services/accept.service';
import { ToastService } from '../services/toast.service';

@Component({
  selector: 'app-webservice-page',
  templateUrl: './webservice-page.component.html',
  styleUrls: ['./webservice-page.component.scss']
})
export class WebservicePageComponent implements OnInit, OnDestroy {
  alive: boolean = true;
  emailToken: FormControl = new FormControl(null,[Validators.required,Validators.email]);

  constructor(
    public sessionService: SessionService,
    private acceptService: AcceptService,
    private toastService: ToastService,
    @Inject(APP_ENVIRONMENT) public env: AppEnvironment,
  ) { }

  ngOnDestroy(): void {
    this.alive=false;
  }

  ngOnInit(): void {
    // window.scrollTo(0,0);
  }

  setActive(id: string){
    let elem=document.getElementById(id);
    if(elem){
      elem.setAttribute('class','active');
    }
  }

  clearClass(id: string, form: FormControl){
    let elem=document.getElementById(id);
    if(elem && (!form.value || form.value?.length==0)){
      elem.setAttribute('class','');
    }
  }

  accept(){
    this.acceptService.acceptLicense(this.emailToken.value).pipe(takeWhile(()=>this.alive))
    .subscribe(res=>{
      this.toastService.showSuccessToast('licenseAcceptance','licenzaAccettata');
    });
  }

  download(){
    this.acceptService.downloadManual();
  }

}
