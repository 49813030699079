<div class="container">
    <div *ngIf="!showSearch">
        <h1 class="primary-color-a8 text-center my-5">
            {{'searchSection' | translate}}
        </h1>
        <div class="mt-5 row">
            <div class="col-12 col-lg-12" style="text-align: justify;" aria-label="Section description">
                {{'searchPageDescr' | translate}}
            </div>
        </div>
        <div class="mb-5 mt-2 row">
            <div class="col-12 col-lg-12" style="text-align: justify;" aria-label="Section description">
                {{'searchPageDescr2' | translate}}
            </div>
        </div>
    </div>
    <div class="accordion accordion-flush" id="accordionSearch"
    *ngIf="showSearch && (sessionService.configs?.ricercaPuntualeDisponibile || sessionService.configs?.ricercaParametriDisponibile)">
        <div class="accordion-item" *ngIf="sessionService.configs?.ricercaPuntualeDisponibile">
            <h2 class="accordion-header" id="punctSearchHeader">
                <button
                class="accordion-button collapsed nav-footer-color"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#punctSearchContent"
                aria-expanded="false"
                aria-controls="punctSearchContent"
                [attr.aria-label]="'punctSearch' | translate"
                >
                    {{'punctSearch' | translate}}
                </button>
            </h2>
            <div
                id="punctSearchContent"
                class="accordion-collapse collapse accordion-body-color"
                aria-label="punctSearchHeader"
                data-bs-parent="#accordionSearch"
            >
                <div class="accordion-body py-4">
                    
                    <form class="form-group row mb-0" [formGroup]="punctualSearch">
                        <div class="col-12 col-lg-3 pos-relative mb-4" [ngClass]="{'disabled': checkFields('ragioneSociale')}">
                            <label for="socialReason" id="label-socialReason">{{'socialReason' | translate}} {{!checkFields('ragioneSociale') ? '*' : ''}}</label>
                            <input type="text" id="socialReason" formControlName="ragioneSociale" class="form-control" aria-labelledby="label-socialReason"
                            [ngClass]="{'is-invalid' : !punctualSearch.get('ragioneSociale')?.valid}" [tabindex]="checkFields('ragioneSociale') ? -1 : ''"
                            (focus)="setActive('label-socialReason')" (blur)="clearClass('label-socialReason','ragioneSociale',punctualSearch)">
                        </div>
                        <div class="col-12 col-lg-3 pos-relative mb-4" [ngClass]="{'disabled': checkFields('nace')}">
                            <label for="naceCode" id="label-naceCode">{{'naceCode' | translate}} {{!checkFields('nace') ? '*' : ''}}</label>
                            <input type="text" id="naceCode" formControlName="nace" class="form-control" aria-labelledby="label-naceCode"
                            [ngClass]="{'is-invalid' : !punctualSearch.get('nace')?.valid}" [tabindex]="checkFields('nace') ? -1 : ''"
                            (focus)="setActive('label-naceCode')" (blur)="clearClass('label-naceCode','nace',punctualSearch)">
                        </div>
                        <div class="col-12 col-lg-3 pos-relative mb-4" [ngClass]="{'disabled': checkFields('cap')}">
                            <label for="cap" id="label-cap">{{'cap' | translate}} {{!checkFields('cap') ? '*' : ''}}</label>
                            <input type="text" id="cap" formControlName="cap" class="form-control" aria-labelledby="label-cap"
                            [ngClass]="{'is-invalid' : !punctualSearch.get('cap')?.valid}" [tabindex]="checkFields('cap') ? -1 : ''"
                            (focus)="setActive('label-cap')" (blur)="clearClass('label-cap','cap',punctualSearch)">
                        </div>
                        <div class="col-12 col-lg-3 pos-relative mb-4" [ngClass]="{'disabled': checkFields('cf')}">
                            <label for="cf" id="label-cf">{{'cf' | translate}} {{!checkFields('cf') ? '*' : ''}}</label>
                            <input type="text" id="cf" formControlName="cf" class="form-control" aria-labelledby="label-cf"
                            [ngClass]="{'is-invalid' : !punctualSearch.get('cf')?.valid}" [tabindex]="checkFields('cf') ? -1 : ''"
                            (focus)="setActive('label-cf')" (blur)="clearClass('label-cf','cf',punctualSearch)">
                        </div>
                    </form>
                    <form #form="ngForm">
                        <div class="row mt-3">
                          <div class="col-sm-12 mb-2">
                            <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel" 
                            [(ngModel)]="token" required (resolved)="checkLang($event,'recaptcha')"
                            [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)">
                            </re-captcha>
                          </div>
                        </div>
                    </form>
                    <div>
                        <button class="btn btn-primary" (click)="search(form,'punctSearchContent')"
                        [disabled]="checkValidPunct() || recaptcha.invalid"
                        [attr.aria-label]="'searchBtn' | translate">
                            {{'searchBtn' | translate}}
                        </button>
                        <button class="btn btn-outline-primary bg-white ms-2" (click)="clearForm('punctSearchContent')"
                        [attr.aria-label]="'clearBtn' | translate">
                            {{'clearBtn' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="accordion-item" *ngIf="sessionService.configs?.ricercaParametriDisponibile">
            <h2 class="accordion-header" id="paramSearchHeader">
                <button
                class="accordion-button collapsed nav-footer-color"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#paramSearchContent"
                aria-expanded="false"
                aria-controls="paramSearchContent"
                [attr.aria-label]="'paramSearch' | translate"
                >
                    {{'paramSearch' | translate}}
                </button>
            </h2>
            <div
                id="paramSearchContent"
                class="accordion-collapse collapse accordion-body-color"
                aria-label="paramSearchHeader"
                data-bs-parent="#accordionSearch"
            >
                <div class="accordion-body py-4">
                    
                    <form class="form-group row mb-0" [formGroup]="paramSearch">
                        <div class="col-12 col-lg-3 pos-relative mb-4">
                            <label for="capParam" id="label-capParam">{{'cap' | translate}} *</label>
                            <input type="text" id="capParam" formControlName="cap" class="form-control" aria-labelledby="label-capParam"
                            [ngClass]="{'is-invalid' : !paramSearch.get('cap')?.valid}"
                            (focus)="setActive('label-capParam')" (blur)="clearClass('label-capParam','cap',paramSearch)">
                        </div>
                        <div class="col-12 col-lg-3 pos-relative mb-4">
                            <label for="naceCodeParam" id="label-naceCodeParam">{{'naceCodeParam' | translate}} *</label>
                            <input type="text" id="naceCodeParam" formControlName="nace" class="form-control" aria-labelledby="label-naceCodeParam"
                            [ngClass]="{'is-invalid' : !paramSearch.get('nace')?.valid}"
                            (focus)="setActive('label-naceCodeParam')" (blur)="clearClass('label-naceCodeParam','nace',paramSearch)">
                        </div>
                        <div class="col-12 col-lg-3 pos-relative mb-4">
                            <div class="pos-relative">
                                <div class="d-flex align-items-center justify-content-center">
                                    <div class="me-2">
                                        {{'statusLabel' | translate}}
                                    </div>
                                    <div class="primary-color-a8 d-flex align-items-center">
                                        <input type="checkbox" formControlName="statoImpresa" id="statoImpresa"
                                        #statoImpresa style="display: none;">
                                        <mat-icon (click)="statoImpresa.click()" class="cursor-pointer"
                                        *ngIf="statoImpresa.checked">check_box</mat-icon>
                                        <mat-icon (click)="statoImpresa.click()" class="cursor-pointer"
                                        *ngIf="!statoImpresa.checked">check_box_outline_blank</mat-icon>
                                    </div>
                                </div>
                                <!-- <button class="form-select form-control" data-bs-toggle="collapse" data-bs-target="#statusSelectDropdown"
                                aria-expanded="false" [ngClass]="{'is-invalid' : !paramSearch.get('statoImpresa')?.valid}"
                                tabindex="20" [attr.aria-label]="'statusLabel' | translate">
                                    <div>{{getLabel(paramSearch,'statoImpresa',statusList) | translate}}</div>
                                </button>
                                <div class="collapse dropdown-custom" id="statusSelectDropdown">
                                    <div class="dropdown-container"> 
                                        <button class="select-item" data-bs-toggle="collapse" data-bs-target="#statusSelectDropdown"
                                        *ngFor="let l of statusList" (click)="paramSearch.patchValue({statoImpresa: l.value})"
                                        [tabIndex]="20" [attr.aria-label]="l.label | translate">
                                            {{l.label | translate}}
                                        </button>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-12 col-lg-3 pos-relative mb-4">
                            <!-- <label class="active"></label> -->
                            <div class="pos-relative" style="margin-top: -1rem;">
                                <mat-form-field class="w-100">
                                    <mat-label >{{'legalForm' | translate}} *</mat-label>
                                    <input type="text" matInput formControlName="formaGiuridica" [matAutocomplete]="auto"
                                    [ngClass]="{'is-invalid' : !checkAuto()}"
                                    [attr.aria-label]="'legalForm' | translate" class="autocomplete">

                                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.codice">
                                            {{option.descrizione! | translate}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                    
                                <!-- <button class="form-select form-control" data-bs-toggle="collapse" data-bs-target="#legalFormSelectDropdown"
                                aria-expanded="false" [ngClass]="{'is-invalid' : !paramSearch.get('formaGiuridica')?.valid}" tabindex="21"
                                [attr.aria-label]="'legalForm' | translate">
                                    <div>{{getLabel(paramSearch,'formaGiuridica',legalForms) | translate}}</div>
                                </button>
                                <div class="collapse dropdown-custom" id="legalFormSelectDropdown">
                                    <div class="dropdown-container"> 
                                        <button class="select-item" data-bs-toggle="collapse" data-bs-target="#legalFormSelectDropdown"
                                        *ngFor="let l of legalForms" (click)="paramSearch.patchValue({formaGiuridica: l.codice})"
                                        tabindex="21" [attr.aria-label]="l.descrizione! | translate">
                                            {{l.descrizione! | translate}}
                                        </button>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </form>
                    <form #form2="ngForm">
                        <div class="row mt-3">
                          <div class="col-sm-12 mb-2">
                            <re-captcha id="recaptcha2" name="recaptcha2" #recaptcha2="ngModel" 
                            [(ngModel)]="token2" required  (resolved)="checkLang($event,'recaptcha2')"
                            [class.is-invalid]="recaptcha2.invalid && (recaptcha2.dirty || recaptcha2.touched)">
                            </re-captcha>
                          </div>
                        </div>
                    </form>
                    <div>        
                        <button class="btn btn-primary" (click)="search(form2,'paramSearchContent')"
                        [attr.aria-label]="'searchBtn' | translate"
                        [disabled]="checkValidParam() || recaptcha2.invalid || !checkAuto(false)">
                            {{'searchBtn' | translate}}
                        </button>
                        <button class="btn btn-outline-primary bg-white ms-2" (click)="clearForm('paramSearchContent')"
                        [attr.aria-label]="'clearBtn' | translate">
                            {{'clearBtn' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-4 container" *ngIf="showSearch && visibleSearch">
        <div>
            <div class="d-flex justify-content-between align-items-center">
                <h6 class="primary-color-a8 my-4">
                    <!-- {{'results' | translate}} -->
                    {{'totalElements' | translate}}: {{totalElements}}
                </h6>
                <button *ngIf="results && results.length>0"
                class="btn btn-outline-primary bg-white" style="height: fit-content;"
                (click)="exportCsv()" [attr.aria-label]="'export' | translate">
                    {{'export' | translate}} CSV
                </button>
            </div>
            <!-- <div class="table-container">
                <table class="table" *ngIf="results && results.length>0">
                    <thead>
                        <tr>
                            <th *ngFor="let th of tableHeaders">{{th.label | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of getShownResults()">
                            <td *ngFor="let col of tableCols">
                                {{getRowValue(row,col)}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row" *ngIf="results && results.length>0">
                <div class="col-12 col-lg-3 text-center extra-info-pagination d-flex align-items-center
                justify-content-center" aria-label="Size selection">
                    {{'elementsPerPage' | translate}}: 
                    <div class="pos-relative ms-2">
                        <button class="form-select form-control" data-bs-toggle="collapse" 
                        data-bs-target="#sizeSelectDropdown" tabindex="25"
                        aria-expanded="false" [attr.aria-label]="size">
                            <div>{{size}}</div>
                        </button>
                        <div class="collapse dropdown-custom" id="sizeSelectDropdown">
                            <div class="dropdown-container"> 
                                <button class="select-item" data-bs-toggle="collapse" 
                                data-bs-target="#sizeSelectDropdown" tabindex="26"
                                [attr.aria-label]="s"
                                *ngFor="let s of sizeList" (click)="getPages(s)">
                                    {{s}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <nav class="pagination pagination-wrapper justify-content-center">
                        <div class="page-item">
                            <button class="page-link cursor-pointer" (click)="changePage('--')"
                            [ngClass]="{'disabled' : page==0}"
                            aria-label="First Page" [tabIndex]="page==0 ? -1 : 27">
                                <mat-icon>keyboard_arrow_left</mat-icon>
                                <mat-icon style="margin-left: -1rem;">keyboard_arrow_left</mat-icon>
                            </button>
                        </div>
                        <div class="page-item">
                            <button class="page-link cursor-pointer" (click)="changePage('-')"
                            [ngClass]="{'disabled' : page==0}"
                            aria-label="Previous Page" [tabIndex]="page==0 ? -1 : 28">
                                <mat-icon>keyboard_arrow_left</mat-icon>
                            </button>
                        </div>
                        <div class="page-item">
                            <div class="page-link disabled"
                            style="color: #5d7083 !important; opacity: 1 !important;">{{page+1}}</div>
                        </div>
                        <div class="page-item">
                            <button class="page-link cursor-pointer" (click)="changePage('+')"
                            [ngClass]="{'disabled' : page==(totalPages-1)}"
                            aria-label="Following Page" [tabIndex]="page==(totalPages-1) ? -1 : 29">
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </button>
                        </div>
                        <div class="page-item">
                            <button class="page-link cursor-pointer" (click)="changePage('++')"
                            [ngClass]="{'disabled' : page==(totalPages-1)}"
                            aria-label="Last Page" [tabIndex]="page==(totalPages-1) ? -1 : 30">
                                <mat-icon style="margin-right: -1rem;">keyboard_arrow_right</mat-icon>
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </button>
                        </div>
                    </nav>
                </div>
                <div class="col-12 col-lg-3 text-center extra-info-pagination">
                    {{'totalElements' | translate}}: {{totalElements}}
                </div>
            </div>
        </div>
        <div *ngIf="!results || results.length==0">
            {{'noResults' | translate}}
        </div> -->
        </div>
    </div>
    <div class="row m-0 p-4 primary-border-color-a8 border" *ngIf="!showSearch">
        <div class="row m-0 px-0">
            <div class="row m-0 pt-4 pb-3 px-0">
                <div class="col-12 fst-italic">
                    {{'noteEmail' | translate}}
                </div>
            </div>
            <div class="row m-0 pt-3 pb-4 px-0">
                <div class="form-group col-12 col-lg-6">
                    <label for="emailToken" id="label-emailToken">{{'email' | translate}}*</label>
                    <input type="text" id="emailToken" [formControl]="emailToken" class="form-control" aria-labelledby="label-emailToken"
                    [ngClass]="{'is-invalid' : emailToken.invalid}"
                    (focus)="setActive('label-emailToken')" (blur)="clearClass2('label-emailToken',emailToken)">
                </div>
            </div>
        </div>
        <div class="m-0 p-4 primary-border-color-a8 primary-bg-c1 border"
        style="text-align: justify;">
            <h4>
                {{'licenza' | translate}}
            </h4>
            <div class="w-100">
                {{'licenza1_1' | translate}}                
                {{'licenza1_2' | translate}}
                <span class="fst-italic">{{'licenza1_3' | translate}}</span>                
                {{'licenza1_4' | translate}}
                <button (click)="download()" class="btn btn-link btn-link-custom">{{'licenza1_5' | translate}}</button>
                {{'licenza1_6' | translate}}
                <br>                
                {{'licenza1_7' | translate}}
                <span class="fw-bold">"{{'licenza1_8' | translate}}"</span>{{'licenza1_9' | translate}}                
            </div>
            <div class="w-100 mt-2">
                {{'licenza2_1' | translate}}
                <a [href]="env.licenzaLink2" target="_blank" class="fst-italic">{{'licenza2_link' | translate}}</a>
                {{'licenza2_2' | translate}}
                <br>
                {{'licenza2_3' | translate}}
                <br>
                {{'licenza2_4' | translate}}
                <br>
                {{'licenza2_5' | translate}}<span class="fst-italic_no">{{'licenza2_5a' | translate}}</span>{{'licenza2_5b' | translate}}
                <br>
                {{'licenza2_6' | translate}}<span class="fst-italic">{{'licenza2_6a' | translate}}</span>{{'licenza2_6b' | translate}}
                <br>
                {{'licenza2_7' | translate}}                
            </div>
            <div class="w-100 mt-2">
                {{'licenza3_1' | translate}}                
            </div>            
        </div>
        <div class="row mx-0 mt-4 mb-0 p-0">
            <div class="col-12 d-flex justify-content-end align-items-center">
                <button class="btn btn-primary" (click)="accept()"
                [disabled]="emailToken.invalid" style="width: fit-content;">
                    {{'accept' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>
