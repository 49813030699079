import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { APP_ENVIRONMENT, SessionService } from '../services/session.service';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { AppEnvironment } from '../model/env';
import { Observable } from 'rxjs';
import { ResponseJiraTicket } from '../model/response.model';
import { ToastService } from '../services/toast.service';
import { FaqService } from '../services/faq.service';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-faq-page',
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.scss']
})
export class FaqPageComponent implements OnInit, OnDestroy {
  scriviciForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    surname: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required, Validators.pattern(/^\d{3,10}$/)]),
    profile: new FormControl('', [Validators.required]),
    organization: new FormControl('', []),
    reason: new FormControl('', [Validators.required]),
    subreason: new FormControl('', [Validators.required]),
    message: new FormControl('', [Validators.required]),    
    attachment: new FormControl(null, []),
    fileName: new FormControl(null, [])
  });

  profili = [
    {value: "Impresa", label: "Impresa"},
    {value: "Professionista", label: "Professionista"},
    {value: "Pubblica Amministrazione", label: "Pubblica Amministrazione"},
    {value: "Privato", label: "Privato"},
    {value: "Altro", label: "Altro"}
  ]

  motivi = [
    {value: "Download a blocchi", label: "Download a blocchi"},
    {value: "API", label: "API"}
  ]

  sottomotivi: any = []

  privacyForm: FormGroup = new FormGroup({
    acceptPrivacy: new FormControl(false, Validators.requiredTrue)
  });

  alive: boolean = true;
  token: string | undefined; 
  selectedFile: string | undefined;

  constructor(
    public sessionService: SessionService,
    private toastService: ToastService,
    private faqService: FaqService,
    private translateService: TranslateService,    
    @Inject(APP_ENVIRONMENT) public env: AppEnvironment,
  ) { }

  checkFields(field: string){
    let keysPunct = Object.keys(this.scriviciForm.value);
    switch(field){
      case 'name':
        return this.scriviciForm.get('name')?.value?.length > 0;
      case 'surname':
        return this.scriviciForm.get('surname')?.value?.length > 0;
      case 'email':
        return this.scriviciForm.get('email')?.value?.length > 0;
      case 'phone':
        return this.scriviciForm.get('phone')?.value?.length > 0;
      case 'profile':
        return this.scriviciForm.get('profile')?.value?.length > 0;
      case 'reason':
        return this.scriviciForm.get('reason')?.value?.length > 0;
      case 'subreason':
        return this.scriviciForm.get('subreason')?.value?.length > 0;
      case 'message':
        return this.scriviciForm.get('message')?.value?.length > 0;
      default:
    }
    return false;
  }

  setActive(id: string) {
    let elem = document.getElementById(id);
    if (elem) {
      elem.setAttribute('class','active');
    }
  }

  clearClass(id: string, form: FormGroup) {
    let elem = document.getElementById(id);
    if (elem && (!form.value || form.value?.length == 0)) {
      elem.setAttribute('class','');
    }
  }

  checkLangCaptcha(lang: string, id: string, check: boolean = false){
    let i = 0;
    let interval = setInterval(()=>{
      const container = document.getElementById(id);

      if (container) {
      // Get GoogleCaptcha iframe
        const iframeGoogleCaptcha = container.querySelector('iframe');
        // Get language code from iframe
        const actualLang = iframeGoogleCaptcha?.getAttribute("src")?.match(/hl=(.*?)&/)?.pop();
        // For setting new language
        if ((actualLang !== lang.toLowerCase() || check) && iframeGoogleCaptcha) {
          iframeGoogleCaptcha.setAttribute("src", iframeGoogleCaptcha.getAttribute("src")!.replace(/hl=(.*?)&/, 'hl=' + lang.toLowerCase() + '&'));
        }
        
        if ((iframeGoogleCaptcha) || i>20) clearInterval(interval);
        i++;
      }

    },100);
  }

  checkLang(e: any, id: string) {
    if(!e) this.checkLangCaptcha(this.sessionService.$lang.value,id);
  }

  changeReason(motivo: any) {    
    this.scriviciForm.patchValue({
      reason: motivo.value,
      subreason: ''
    });
    this.sottomotivi = [
      {value: "Condizioni di Utilizzo", label: "Condizioni di Utilizzo"},
      {value: "Estrazione Dati", label: "Estrazione Dati"},
      {value: "Info generiche", label: "Info generiche"}
    ]
  }

  onFileChange(event: any): void {
    if (event.target.files.length > 0) {      
      const file: File = event.target.files[0];      
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64String = reader.result as string;
          // Rimuovi il prefisso 'data:application/pdf;base64,' se presente
          const base64 = base64String.split(',')[1];          
          this.scriviciForm.get('fileName')?.setValue(file?.name);
          this.selectedFile = base64;
        };                   
      }        
    }
  }

  async send(form: NgForm) {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }       
    let jiraTicketRequest = {
      "name": this.scriviciForm.get('name')?.value,
      "surname": this.scriviciForm.get('surname')?.value,
      "email": this.scriviciForm.get('email')?.value,
      "phone": this.scriviciForm.get('phone')?.value,
      "profile": this.scriviciForm.get('profile')?.value,
      "organization": this.scriviciForm.get('organization')?.value,
      "reason": this.scriviciForm.get('reason')?.value,
      "subreason": this.scriviciForm.get('subreason')?.value,
      "message": this.scriviciForm.get('message')?.value,
      "recaptchaToken": this.token,      
      "attachment": this.selectedFile,
      "fileName": this.scriviciForm.get('fileName')?.value      
    }    
    let api: Observable<ResponseJiraTicket> = new Observable<ResponseJiraTicket>();
    api = this.faqService.openJiraTicket(jiraTicketRequest);
    await api.pipe(takeWhile(()=>this.alive)).toPromise()
    .then(res => {
      this.toastService.showSuccessToast('200', 'jiraOk', {ticket: res.key});
      this.clearForm();
      window.scrollTo(0,0);
    },error=>{
      this.clearForm();
    });
  }

  clearForm() {
    this.scriviciForm.patchValue({
      name: '',
      surname: '',     
      email: '',
      phone: '',
      profile: '',
      organization: '',
      reason: '',
      subreason: '',
      message: '',     
      attachment: null, 
      fileName: ''
    });
    this.sottomotivi = [];
    this.selectedFile = '';
    this.token = undefined;   
    this.checkLangCaptcha(this.sessionService.$lang.value,'recaptchaFaq',true);
    this.privacyForm.patchValue({
      acceptPrivacy: false
    });
    this.scriviciForm.updateValueAndValidity();
  }

  checkValidity() {
    let checkScrivici = this.scriviciForm.get('name')?.value?.length > 0 &&
        this.scriviciForm.get('surname')?.value?.length > 0 && 
        this.scriviciForm.get('email')?.value?.length > 0 &&
        this.scriviciForm.get('phone')?.value?.length > 0 &&      
        this.scriviciForm.get('profile')?.value?.length > 0 &&
        this.scriviciForm.get('reason')?.value?.length > 0 &&      
        this.scriviciForm.get('subreason')?.value?.length > 0 &&
        this.scriviciForm.get('message')?.value?.length > 0;    
    let checkPrivacy = this.privacyForm.get('acceptPrivacy')?.value;    
    return !checkScrivici || !checkPrivacy;
  }

  ngOnDestroy(): void {
    this.alive=false;
  }

  ngOnInit(): void {     
  }

  ngAfterViewInit(): void {
    this.sessionService.$lang.pipe(takeWhile(()=>this.alive)).subscribe(lang=>{
      this.checkLangCaptcha(lang,'recaptchaFaq');
    });
  }

  onKeydown(e: any, obj: any, form: FormGroup | null = null){
    if(e.keyCode==32 || e.keyCode==13){
      if(form) form.patchValue(obj);
      else this.changeReason(obj)
    }
  }

}
