<!-- <div class="d-flex justify-content-center primary-bg-a8 text-white h-100
align-items-center">
    <ul class="nav container justify-content-between align-items-center h-100">
        <li class="nav-item cursor-pointer h-100 d-flex align-items-center">
            <a class="logo-header" (click)="goTo('home')" aria-label="Logo" [tabIndex]="1"
            (keydown)="onKeydown($event)"></a>
        </li>
        <li class="nav-item h-100 d-flex align-items-center">
            <h2 tabindex="-1">Public Sector Information</h2>
        </li>
        <li class="nav-item h-100 d-flex align-items-center" style="position: relative;">
            <a class="logo-header" style="z-index: 0; opacity: 0;" tabindex="-1"></a>
            <div class="w-100 d-flex justify-content-center pos-absolute">
                <div class="pos-relative">
                    <button class="form-select form-control form-select-header" data-bs-toggle="collapse" data-bs-target="#languageSelectDropdown"
                    tabindex="2" aria-expanded="false">
                        <div>{{lang}}</div>
                    </button>
                    <div class="collapse dropdown-custom" id="languageSelectDropdown">
                        <div class="dropdown-container"> 
                            <button class="select-item" data-bs-toggle="collapse" data-bs-target="#languageSelectDropdown"
                            *ngFor="let l of sessionService.langs; let i=index" (click)="changeLang(l)" [tabIndex]="2">
                                {{l}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</div> -->
<div class="primary-bg-a8 text-white h-100">
    <div class="container d-flex justify-content-between align-items-center h-100">
        <div class="cursor-pointer h-100 d-flex align-items-center justify-content-center"
        style="position: relative;">
            <a class="logo-header logo_info_bianco" style="z-index: 0; opacity: 0;" tabindex="-1"></a>
            <button class="btn btn-link pos-absolute text-white" (click)="showSide.emit()" aria-label="Logo">
                <mat-icon>menu</mat-icon>
            </button>
        </div>
        <div class="h-100 d-flex align-items-center text-center">
            <button class="btn btn-link text-white" (click)="goTo('home')">
                <h2 class="title-small" aria-label="PSI">{{'siteTitle' | translate}}</h2></button>
            <!-- <h2 tabindex="1" class="small-title cursor-pointer" aria-label="PSI"
            (click)="goTo('home')">PSI</h2> -->
        </div>
        <div class="h-100 d-flex align-items-center" style="position: relative;">
            <a class="logo-header logo_info_bianco" style="z-index: 0; opacity: 0;" tabindex="-1"></a>
            <div class="w-100 d-flex justify-content-center pos-absolute"
            *ngIf="sessionService.configs?.componenteMultilinguaDisponibile">
                <div class="pos-relative">
                    <button class="form-select form-control form-select-header" data-bs-toggle="collapse" data-bs-target="#languageSelectDropdown"
                    aria-expanded="false" aria-label="Language">
                        <div>{{lang}}</div>
                    </button>
                    <div class="collapse dropdown-custom" id="languageSelectDropdown">
                        <div class="dropdown-container"> 
                            <button class="select-item"
                            *ngFor="let l of sessionService.langs; let i=index" (click)="changeLang(l)"
                            [attr.aria-label]="l" (keydown)="cLang($event,l)">
                                {{l}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>