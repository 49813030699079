<div class="container">
    <h1 class="primary-color-a8 text-center my-5" [attr.aria-label]="'section2' | translate">
        {{'section2PageTitle' | translate}}
    </h1>
    <div class="my-5 row">
        <div class="col-12 col-lg-12" style="text-align: justify;" aria-label="Section description">
            {{'section2PageDescr' | translate}}
        </div>
    </div>
    <div class="accordion accordion-flush" id="accordionFaq">
        <div class="accordion-item">
            <h2 class="accordion-header" id="firstFaq">
                <button
                class="accordion-button collapsed nav-footer-color"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#firstFaqAnswer"
                aria-expanded="false"
                aria-controls="firstFaqAnswer"
                [attr.aria-label]="'faq1' | translate"
                >
                    {{'faq1' | translate}}
                </button>
            </h2>
            <div
                id="firstFaqAnswer"
                class="accordion-collapse collapse accordion-body-color"
                aria-label="firstFaq"
                data-bs-parent="#accordionFaq"
            >
                <div class="accordion-body p-3">
                    <div class="primary-color-a8">
                        {{'answer1' | translate}}
                        <a href="https://www.agid.gov.it/sites/default/files/repository_files/guida_operativa_hvd_-_ver._1.0.pdf"
                        target="_blank">link</a>.
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="secondFaq">
                <button
                class="accordion-button collapsed nav-footer-color"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#secondFaqAnswer"
                aria-expanded="false"
                aria-controls="secondFaqAnswer"
                [attr.aria-label]="'faq2' | translate"
                >
                    {{'faq2' | translate}}
                </button>
            </h2>
            <div
                id="secondFaqAnswer"
                class="accordion-collapse collapse accordion-body-color"
                aria-label="secondFaq"
                data-bs-parent="#accordionFaq"
            >
                <div class="accordion-body p-3">
                    <div class="primary-color-a8">
                        {{'answer2' | translate}}                                            
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="thirdFaq">
                <button
                class="accordion-button collapsed nav-footer-color"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#thirdFaqAnswer"
                aria-expanded="false"
                aria-controls="thirdFaqAnswer"
                [attr.aria-label]="'faq3' | translate"
                >
                    {{'faq3' | translate}}
                </button>
            </h2>
            <div
                id="thirdFaqAnswer"
                class="accordion-collapse collapse accordion-body-color"
                aria-label="thirdFaq"
                data-bs-parent="#accordionFaq"
            >
                <div class="accordion-body p-3">
                    <div class="primary-color-a8" aria-label="API url">
                        {{'answer3' | translate}}
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="fourthFaq">
                <button
                class="accordion-button collapsed nav-footer-color"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#fourthFaqAnswer"
                aria-expanded="false"
                aria-controls="fourthFaqAnswer"
                [attr.aria-label]="'faq4' | translate"
                >
                    {{'faq4' | translate}}
                </button>
            </h2>
            <div
                id="fourthFaqAnswer"
                class="accordion-collapse collapse accordion-body-color"
                aria-label="fourthFaq"
                data-bs-parent="#accordionFaq"
            >
                <div class="accordion-body p-3">
                    <div class="primary-color-a8">
                        {{'answer4_a' | translate}}
                        <a href="https://www.agid.gov.it/sites/default/files/repository_files/guida_operativa_hvd_-_ver._1.0.pdf"
                        target="_blank">link</a>.
                        {{'answer4_b' | translate}}
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="fifthFaq">
                <button
                class="accordion-button collapsed nav-footer-color"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#fifthFaqAnswer"
                aria-expanded="false"
                aria-controls="fifthFaqAnswer"
                [attr.aria-label]="'faq5' | translate"
                >
                    {{'faq5' | translate}}
                </button>
            </h2>
            <div
                id="fifthFaqAnswer"
                class="accordion-collapse collapse accordion-body-color"
                aria-label="fifthFaq"
                data-bs-parent="#accordionFaq"
            >
                <div class="accordion-body p-3">
                    <div class="primary-color-a8">
                        {{'answer5' | translate}}
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="sixthFaq">
                <button
                class="accordion-button collapsed nav-footer-color"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#sixthFaqAnswer"
                aria-expanded="false"
                aria-controls="sixthFaqAnswer"
                [attr.aria-label]="'faq6' | translate"
                >
                    {{'faq6' | translate}}
                </button>
            </h2>
            <div
                id="sixthFaqAnswer"
                class="accordion-collapse collapse accordion-body-color"
                aria-label="sixthFaq"
                data-bs-parent="#accordionFaq"
            >
                <div class="accordion-body p-3">
                    <div class="primary-color-a8">
                        {{'answer6' | translate}}
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="seventhFaq">
                <button
                class="accordion-button collapsed nav-footer-color"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#seventhFaqAnswer"
                aria-expanded="false"
                aria-controls="seventhFaqAnswer"
                [attr.aria-label]="'faq7' | translate"
                >
                    {{'faq7' | translate}}
                </button>
            </h2>
            <div
                id="seventhFaqAnswer"
                class="accordion-collapse collapse accordion-body-color"
                aria-label="seventhFaq"
                data-bs-parent="#accordionFaq"
            >
                <div class="accordion-body p-3">
                    <div class="primary-color-a8">
                        {{'answer7' | translate}}
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="eighthFaq">
                <button
                class="accordion-button collapsed nav-footer-color"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#eighthFaqAnswer"
                aria-expanded="false"
                aria-controls="eighthFaqAnswer"
                [attr.aria-label]="'faq8' | translate"
                >
                    {{'faq8' | translate}}
                </button>
            </h2>
            <div
                id="eighthFaqAnswer"
                class="accordion-collapse collapse accordion-body-color"
                aria-label="eighthFaq"
                data-bs-parent="#accordionFaq"
            >
                <div class="accordion-body p-3">
                    <div class="primary-color-a8">
                        {{'answer8' | translate}}
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="ninthFaq">
                <button
                class="accordion-button collapsed nav-footer-color"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#ninthFaqAnswer"
                aria-expanded="false"
                aria-controls="ninthFaqAnswer"
                [attr.aria-label]="'faq9' | translate"
                >
                    {{'faq9' | translate}}
                </button>
            </h2>
            <div
                id="ninthFaqAnswer"
                class="accordion-collapse collapse accordion-body-color"
                aria-label="ninthFaq"
                data-bs-parent="#accordionFaq"
            >
                <div class="accordion-body p-3">
                    <div class="primary-color-a8">
                        {{'answer9' | translate}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div style="height: 50px;"></div>

<div class="container" id="formScrivici">
    <div class="row mt-0 mb-5 ms-1">
        <div class="col-12 col-lg-12" style="text-align: justify;" aria-label="Write us">
            <p>{{'writeUs' | translate}}</p>
        </div>
    </div>
    <!--<div class="accordion accordion-flush" id="accordionScrivici">
        <div class="accordion-item">              
            <h2 class="accordion-header" id="scriviciHeader">
                <button tabindex="11"
                class="accordion-button collapsed nav-footer-color"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#scriviciContent"
                aria-expanded="false"
                aria-controls="scriviciContent"
                [attr.aria-label]="'writeUs' | translate"
                >
                    {{'writeUs' | translate}}
                </button>
            </h2>
            <div id="scriviciContent" class="accordion-collapse collapse accordion-body-color"
                aria-label="scriviciHeader">                           
                <br />
                <br />-->
                <form class="form-group row mb-0 mx-2" [formGroup]="scriviciForm" id="scriviciForm">                    
                    <div class="row col-12">
                        <div class="col-12 col-lg-4 pos-relative mb-5">
                            <label for="name" id="label-name" class="active">{{'nameLabel' | translate}} *</label>
                            <input type="text" id="name" formControlName="name" class="form-control" aria-labelledby="label-name"
                            [ngClass]="{'is-invalid' : !scriviciForm.get('name')?.valid}" placeholder="{{'namePlaceholder' | translate}}"
                            (focus)="setActive('label-name')" (blur)="clearClass('label-name', scriviciForm)">
                        </div>
                        <div class="col-12 col-lg-4 pos-relative mb-5">
                            <label for="surname" id="label-surname" class="active">{{'surnameLabel' | translate}} *</label>
                            <input type="text" id="name" formControlName="surname" class="form-control" aria-labelledby="label-surname"
                            [ngClass]="{'is-invalid' : !scriviciForm.get('surname')?.valid}" placeholder="{{'surnamePlaceholder' | translate}}"
                            (focus)="setActive('label-surname')" (blur)="clearClass('label-surname', scriviciForm)">
                        </div>
                    </div>
                    <div class="row col-12">
                        <div class="col-12 col-lg-4 pos-relative mb-5">
                            <label for="email" id="label-email" class="active">{{'mailLabel' | translate}} *</label>
                            <input type="email" id="email" formControlName="email" class="form-control" aria-labelledby="label-email"
                            [ngClass]="{'is-invalid' : !scriviciForm.get('email')?.valid}" placeholder="{{'mailPlaceholder' | translate}}"
                            (focus)="setActive('label-email')" (blur)="clearClass('label-email', scriviciForm)">
                        </div>
                        <div class="col-12 col-lg-4 pos-relative mb-5">
                            <label for="phone" id="label-phone" class="active">{{'phoneLabel' | translate}} *</label>
                            <input type="text" id="phone" formControlName="phone" class="form-control" aria-labelledby="label-phone"
                            [ngClass]="{'is-invalid' : !scriviciForm.get('phone')?.valid}" placeholder="{{'phonePlaceholder' | translate}}"
                            (focus)="setActive('label-phone')" (blur)="clearClass('label-phone', scriviciForm)">
                        </div>
                    </div>
                    <div class="row col-12">
                        <div class="col-12 col-lg-4 pos-relative mb-5">
                            <label for="profile" id="label-profile" class="active">{{'profileLabel' | translate}} *</label>   
                            <button class="form-select form-control" data-bs-toggle="collapse" data-bs-target="#profileDropdown"
                            aria-expanded="false" [ngClass]="{'is-invalid' : scriviciForm.get('profile')?.value?.length > 0 && !scriviciForm.get('profile')?.valid}"
                            [attr.aria-label]="'label-profile' | translate">
                                <div>{{scriviciForm.get('profile')?.value ? scriviciForm.get('profile')!.value : 'profilePlaceholder' | translate}}</div>
                            </button>
                            <div class="collapse dropdown-custom" id="profileDropdown">
                                <div class="dropdown-container"> 
                                    <button class="select-item"
                                    *ngFor="let profilo of profili" (click)="scriviciForm.patchValue({profile: profilo.value})"
                                    [attr.aria-label]="profilo.label | translate" (keydown)="onKeydown($event,{profile: profilo.value},scriviciForm)">
                                        {{profilo.label | translate}}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4 pos-relative mb-5">
                            <label for="organization" id="label-organization" class="active">{{'organizationLabel' | translate}}</label>
                            <input type="text" id="organization" formControlName="organization" class="form-control" aria-labelledby="label-organization"
                            [ngClass]="{'is-invalid' : !scriviciForm.get('organization')?.valid}" placeholder="{{'organizationPlaceholder' | translate}}"
                            (focus)="setActive('label-organization')" (blur)="clearClass('label-organization', scriviciForm)">
                        </div>
                    </div>
                    <div class="row col-12">
                        <div class="col-12 col-lg-4 pos-relative mb-5">
                            <label for="reason" id="label-reason" class="active">{{'reasonLabel' | translate}} *</label>   
                            <button class="form-select form-control" data-bs-toggle="collapse" data-bs-target="#reasonDropdown"
                            aria-expanded="false" [ngClass]="{'is-invalid' : scriviciForm.get('reason')?.value?.length > 0 && !scriviciForm.get('reason')?.valid}"
                            [attr.aria-label]="'label-reason' | translate">
                                <div>{{scriviciForm.get('reason')?.value ? scriviciForm.get('reason')!.value : 'reasonPlaceholder' | translate}}</div>
                            </button>
                            <div class="collapse dropdown-custom" id="reasonDropdown">
                                <div class="dropdown-container"> 
                                    <button class="select-item"
                                    *ngFor="let motivo of motivi" (click)="changeReason(motivo)"
                                    [attr.aria-label]="motivo.label | translate" (keydown)="onKeydown($event,motivo)">
                                        {{motivo.label | translate}}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4 pos-relative mb-5">
                            <label for="subreason" id="label-subreason" class="active">{{'subreasonLabel' | translate}} *</label>   
                            <button class="form-select form-control" data-bs-toggle="collapse" data-bs-target="#subreasonDropdown" [attr.disabled]="this.sottomotivi.length === 0 ? '' : null"
                            aria-expanded="false" [ngClass]="{'is-invalid' : scriviciForm.get('subreason')?.value?.length > 0 && !scriviciForm.get('subreason')?.valid}"
                            [attr.aria-label]="'label-subreason' | translate">
                                <div>{{scriviciForm.get('subreason')?.value ? scriviciForm.get('subreason')!.value : 'subreasonPlaceholder' | translate}}</div>
                            </button>
                            <div class="collapse dropdown-custom" id="subreasonDropdown">
                                <div class="dropdown-container"> 
                                    <button class="select-item"
                                    *ngFor="let sottomotivo of sottomotivi" (click)="scriviciForm.patchValue({subreason: sottomotivo.value})"
                                    [attr.aria-label]="sottomotivo.label | translate" (keydown)="onKeydown($event,{subreason: sottomotivo.value},scriviciForm)">
                                        {{sottomotivo.label | translate}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row col-12">
                        <div class="col-12 col-lg-6 pos-relative mb-5">
                            <label for="message" id="label-message" class="active">{{'messageLabel' | translate}} *</label>
                            <span *ngIf="scriviciForm.get('subreason')?.value === 'Estrazione dati'" class="form-control primary-color-a4 messageInfo">
                                <strong>{{'messageInfo' | translate}}</strong>
                            </span>
                            <textarea id="message" formControlName="message" class="form-control" aria-labelledby="label-message"
                            [ngClass]="{'is-invalid' : !scriviciForm.get('message')?.valid}" rows="5" placeholder="{{'messagePlaceholder' | translate}}"
                            (focus)="setActive('label-message')" (blur)="clearClass('label-message', scriviciForm)"></textarea>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-12 col-lg-6 pos-relative">
                            <label for="attachment" id="label-attachment" class="active">{{'attachmentLabel' | translate}} </label>
                            <div class="form-control" 
                            *ngIf="!scriviciForm.get('fileName')?.value || scriviciForm.get('fileName')?.value.length==0">
                                {{'noFile' | translate}}
                            </div>
                            <div class="form-control" *ngIf="scriviciForm.get('fileName')?.value && scriviciForm.get('fileName')?.value.length>0">
                                {{scriviciForm.get('fileName')?.value}}
                            </div>
                            <input type="file" style="display: none;" id="attachment"
                            #fileInput (change)="onFileChange($event)">
                        </div>
                        <div class="col-12 col-lg-6 mt-lg-0 mt-3 ps-lg-2">
                            <button class="btn btn-primary"
                            (click)="fileInput.click()">
                                {{'chooseFile' | translate}}
                            </button>
                            <button class="btn btn-outline-primary bg-white ms-2"
                            *ngIf="scriviciForm.get('fileName')?.value?.length>0"
                            (click)="scriviciForm.patchValue({fileName: null})">
                                {{'removeFile' | translate}}
                            </button>
                        </div>
                    </div>
                </form>   
                <form #formFaq="ngForm" class="mx-2 row mb-2">
                    <re-captcha id="recaptchaFaq" name="recaptchaFaq" #recaptchaFaq="ngModel" 
                    [(ngModel)]="token" required (resolved)="checkLang($event,'recaptchaFaq')"
                    [class.is-invalid]="recaptchaFaq.invalid && (recaptchaFaq.dirty || recaptchaFaq.touched)">
                    </re-captcha>                  
                </form>   
                <form class="row mb-0 mx-2" [formGroup]="privacyForm" >
                    <div class="col-8 mx-2 form-check form-check-inline">
                        <input type="checkbox" id="acceptPrivacy" formControlName="acceptPrivacy">
                        <label for="acceptPrivacy" class="labelCheckbox">{{'acceptPrivacy' | translate }} <a href="https://informative.infocamere.it/informazioni-del-settore-pubblico" target="_blank">link</a>.</label>
                    </div>
                </form>              
                <div class="col-8 mt-3 mb-3 text-center">
                    <button class="btn btn-primary me-4" (click)="send(formFaq)"
                    [disabled]="checkValidity() || recaptchaFaq.invalid"
                    [attr.aria-label]="'sendBtn' | translate">
                        {{'sendBtn' | translate}}
                    </button>
                    <button class="btn btn-outline-primary bg-white ms-2" (click)="clearForm()"
                    [attr.aria-label]="'clearBtn' | translate">
                        {{'clearBtn' | translate}}
                    </button>
                </div>        
            </div>
    <!--    </div>
    </div>
</div>-->