import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { AppEnvironment } from "../model/env";
import { APP_ENVIRONMENT } from "./session.service";
import { ResponseJiraTicket } from "../model/response.model";
import { newArray } from "@angular/compiler/src/util";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class FaqService {

    private apiUrlJira: string = this.env.apiUrl + this.env.jiraTicketSuffix;

    constructor(
        private http: HttpClient,
        @Inject(APP_ENVIRONMENT) private env: AppEnvironment,
    ){}

    openJiraTicket(data: any) {                
        return this.http.post<ResponseJiraTicket>(this.apiUrlJira, data, {withCredentials: true});
    }
}