<!-- container -->
<div style="position: relative;">
  <div id="spinner" *ngIf="sessionService.showSpinner">
    <div class="spinner-border"></div>
  </div>
  
  <!-- header -->
  <div class="header w-100" id="header">
    <app-header (showSide)="showSide()" (hideSide)="hideSide()"></app-header>
  </div>
  <!-- content -->
  <div class="d-flex justify-content-center">
    <div id="sidebar" class="primary-border-color px-2 pos-relative d-none">
      <button class="btn btn-link btn-close pos-absolute" style="top: 1rem; right: 1rem;"
      (click)="showSide()"></button>
      <div>
        <button class="btn btn-link text-left" (click)="goTo('webservice')">
          {{'section1Side' | translate}}
        </button>
      </div>
      <div>
        <button class="btn btn-link text-left" (click)="goTo('research')">
          {{'section3Side' | translate}}
        </button>
      </div>
      <div>
        <button class="btn btn-link text-left" (click)="goTo('faq')">
          {{'section2Side' | translate}}
        </button>
      </div>
    </div>
    <div class="container w-100"
    [ngClass]="{'d-flex align-items-center' : checkErrorPage()}">
      <router-outlet></router-outlet>
    </div>
  </div>
  <!-- footer -->
  <div class="footer w-100 primary-bg-a8">
    <app-footer></app-footer>
  </div>
</div>

<div class="error-dialog">
  <app-error-dialog></app-error-dialog>
</div>


<div class="it-example-modal">
  <div class="modal fade bd-example-modal-md" tabindex="-1" role="dialog" id="acceptModal"
  aria-label="acceptModalLabel" aria-hidden="true" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{'licenseAcceptance' | translate}}
          </h5>
        </div>
        <div class="modal-body">
          {{'lorem' | translate}}
          <br><br>
          {{'acceptLicense' | translate}}
        </div>
        <div class="modal-footer">
          <div style="display: flex; justify-content: end;">
            <div>
              <button class="btn btn-primary btn-sm"
              (click)="close(true)">
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>