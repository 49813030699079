import { Component, OnDestroy, OnInit } from '@angular/core';
import { SessionService } from '../services/session.service';
import { AcceptService } from '../services/accept.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit, OnDestroy {

  alive: boolean = true;

  constructor(
    public sessionService: SessionService,
    private acceptService: AcceptService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnDestroy(): void {
    this.alive=false;
  }

  ngOnInit(): void {
    // window.scrollTo(0,0);
    this.route.queryParamMap.pipe(takeWhile(()=>this.alive)).subscribe(res=>{
      if(res.get('download')=='true')
        window.open('assets/documents/Istruzioni_di_utilizzo.pdf')
    });
  }

  goTo(link: string){
    this.router.navigate([link]);
  }

  download(){
    this.acceptService.downloadManual();
  }

}
