<div class="text-white container py-3">
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-lg-4 d-flex align-items-center justify-content-center mb-3">
            <!-- <a aria-label="Logo Camera" [tabIndex]="1000" #logo_camera
            (keydown)="onKeydown($event,logo_camera)" class="logo-camera logo-footer"
            href="http://www.camcom.gov.it/" target="_blank" rel="noopener"></a> -->
            <div #logo_camera
            class="logo-camera logo-footer"></div>
        </div>
        <div class="col-xs-12 col-sm-12 col-lg-4 d-flex align-items-center justify-content-center mb-3">
            <!-- <a aria-label="Logo Unioncamere" [tabIndex]="1001" #logo_unioncamere
            (keydown)="onKeydown($event,logo_unioncamere)" class="logo-unioncamere logo-footer"
            href="http://www.unioncamere.gov.it/" target="_blank" rel="noopener"></a> -->
            <div #logo_unioncamere
            class="logo-unioncamere logo-footer"></div>
        </div>
        <!-- <div class="col-xs-12 col-sm-6 col-lg-3 d-flex align-items-center justify-content-center mb-3">
            <a aria-label="Logo Agid" [tabIndex]="1002" #logo_agid
            (keydown)="onKeydown($event,logo_agid)" class="logo-agid logo-footer"
            href="https://www.agid.gov.it/" target="_blank" rel="noopener"></a>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-lg-4 d-flex align-items-center justify-content-center mb-3"
        style="flex-direction: column;">
            <span class="mb-2">È un servizio realizzato da</span>
            <!-- <a aria-label="Logo Infocamere" [tabIndex]="1003" #logo_info_bianco
            (keydown)="onKeydown($event,logo_info_bianco)" class="logo-footer logo_info_bianco"
            href="https://www.infocamere.it/" target="_blank" rel="noopener"></a> -->
            <div #logo_info_bianco
            class="logo_info_bianco logo-footer"></div>
        </div>
    </div>
</div>
<div class="text-white container py-3">
    <hr>
    <div class="d-flex align-items-center justify-content-between">
        <div class="footer-p-left">
            <a target="_blank" rel="noopener" aria-label="Note Legali"
            (keydown)="onKeydown($event,note_legali)" #note_legali
            href="https://informative.infocamere.it/informazioni-del-settore-pubblico">
                {{'privacy' | translate}}
            </a>
        </div>
        <span>|</span>                
        <div class="footer-p-left" style="margin-left: 1rem;">
            <a target="_blank" rel="noopener" aria-label="Condizioni"
            (keydown)="onKeydown($event,condizioni)" #condizioni
            href="assets/documents/Condizioni_di_Utilizzo.pdf">
                {{'conditions_use' | translate}}
            </a>
        </div>
        <div class="footer-p-right">
            <span>© 
                <span>2024</span>
                <span>&nbsp;by InfoCamere SCpA - {{'legalAddress' | translate}}: Via G.B. Morgagni 13, 00161 Roma - 
                    {{'pIvaCF' | translate}} 02313821007 - {{'leiCode' | translate}} 815600EAD78C57FCE690
                </span>
            </span>
        </div>
    </div>
</div>
