<div class="container">
    <h1 class="primary-color text-center my-5">
        {{'sections' | translate}}
    </h1>
    <div class="mb-2 row">
        <div class="col-0 col-lg-2"></div>
        <div class="col-12 col-lg-8" style="text-align: justify;" aria-label="Site description">
            {{'sectionsDescr' | translate}}
            "<span style="font-style: italic;">{{'sectionsDescr1_a' | translate}}</span>"
        </div>
        <div class="col-0 col-lg-2"></div>
    </div>
    <div class="mb-2 row">
        <div class="col-0 col-lg-2"></div>
        <div class="col-12 col-lg-8" style="text-align: justify;" aria-label="Site description">
            {{'sectionsDescr2' | translate}}
        </div>
        <div class="col-0 col-lg-2"></div>
    </div>
    <div class="mb-5 row">
        <div class="col-0 col-lg-2"></div>
        <div class="col-12 col-lg-8" style="text-align: justify;" aria-label="Site description">
            {{'sectionsDescr3' | translate}} 
            <button (click)="download()" class="btn btn-link-custom">{{'manuale' | translate}}</button>
            {{'sectionsDescr3a' | translate}}            
        </div>
        <div class="col-0 col-lg-2"></div>
    </div>
    <!-- <div class="row">
        <div class="col-12 col-lg-4 mb-3">
            <button class="card py-5 px-5 border-card cursor-pointer h-100"
            [attr.aria-label]="'section1' | translate" tabindex="10"
            (click)="goTo('webservice')" id="first-card">
                <h4 class="primary-color text-center m-0 card-title w-100">
                    {{'section1' | translate}}
                </h4>
                <div class="card-desc mt-5">
                    {{'section1Desc' | translate}}
                </div>
                <div class="card-desc mt-2">
                    {{'section1Desc2' | translate}}
                </div>
                <div class="card-arrow me-5 mb-2 primary-color">
                    <mat-icon>arrow_right_alt</mat-icon>
                </div>
            </button>
        </div>
        <div class="col-12 col-lg-4 mb-3">
            <button class="card py-5 px-5 border-card cursor-pointer h-100"
            [attr.aria-label]="'section2' | translate" tabindex="11"
            (click)="goTo('faq')" id="second-card">
                <h4 class="primary-color text-center m-0 card-title w-100">
                    {{'section2' | translate}}
                </h4>
                <div class="card-desc mt-5">
                    {{'section2Desc' | translate}}
                </div>
                <div class="card-arrow me-5 mb-2 primary-color">
                    <mat-icon>arrow_right_alt</mat-icon>
                </div>
            </button>
        </div>
        <div class="col-12 col-lg-4 mb-3">
            <button class="card py-5 px-5 border-card cursor-pointer h-100"
            [attr.aria-label]="'section3' | translate" tabindex="12"
            (click)="goTo('research')" id="third-card">
                <h4 class="primary-color text-center m-0 card-title w-100">
                    {{'section3' | translate}}
                </h4>
                <div class="card-desc mt-5">
                    {{'section3Desc' | translate}}
                </div>
                <div class="card-desc mt-2">
                    {{'section3Desc2' | translate}}
                </div>
                <div class="card-arrow me-5 mb-2 primary-color">
                    <mat-icon>arrow_right_alt</mat-icon>
                </div>
            </button>
        </div>
    </div> -->
</div>