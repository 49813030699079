import { Injectable } from '@angular/core';
import { AddressInfoImpresa } from '../model/info-impresa.model';
import { TranslateService } from '@ngx-translate/core';
import { LegalForm } from '../model/legal-form.model';

@Injectable({
    providedIn: 'root'
})
export class CsvService {

  constructor(
    private translateService: TranslateService,
  ) { }

  downloadCsv(data: any[], dataErogazione: Date, filename: string = 'data.csv') {
    const csvData = this.convertToCsv(data,dataErogazione);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const dwldLink = document.createElement("a");
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;

    // Safari & Opera do not support direct link clicking
    if (isSafariBrowser) {
      dwldLink.setAttribute("target", "_blank");
    }

    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  convertToCsv(data: any[], dataErogazione: Date): string {
    let label='elaborationTime';
    let array = [[
      Object.keys(data[0]).filter(x=>x!=label).map(x=>{
        return this.translateService.instant(x);
      }),
      this.translateService.instant(label),
      this.translateService.instant('erogDate')
    ].join(',')];
    let vet: any[] = [];
    for(let d of data){
        vet=[];
        Object.keys(d).forEach(k=>{
          if(k!=label){
            if(k!='indirizzoSedeLegale' && k!='formaGiuridica'){
              let app=d[k] ? (d[k] as string) : '';
              vet.push(app);
            }
            else if(k=='formaGiuridica'){
              let app=d[k] ? ((d[k] as LegalForm).descrizione as string) : '';
              vet.push(app);
            }
            else{
              let app=d[k] as AddressInfoImpresa;
              let appVet=[];
              if(app?.toponimo && app?.via) appVet.push(app.toponimo+' '+app.via);
              if(app?.numeroCivico) appVet.push(app.numeroCivico);
              if(app?.comune) appVet.push(app.comune);
              if(app?.provincia) appVet.push(app.provincia);
              if(app?.cap) appVet.push(app.cap);
              vet.push(appVet.join('_'));
            }
          }
        });
        vet.push(d.elaborationTime),
        vet.push([
          this.formatDate(dataErogazione.getDate()+''),
          this.formatDate((dataErogazione.getMonth()+1)+''),
          dataErogazione.getFullYear()+''
        ].join('/'));
        array.push(vet.join(','));
    }
    return array.join('\n');
  }

  formatDate(d: string){
    if(d.length==1) return '0'+d;
    return d;
  }

}
