import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { AppEnvironment } from "../model/env";
import { InfoImpresa } from "../model/info-impresa.model";
import { APP_ENVIRONMENT } from "./session.service";
import { ResponseInfoImpresa, ResponseLegalForm } from "../model/response.model";

@Injectable({
    providedIn: 'root'
})
export class ResearchService{

    private apiUrlPunct: string = this.env.apiUrl+this.env.punctResearchSuffix;
    private apiUrlParam: string = this.env.apiUrl+this.env.paramResearchSuffix;
    private apiUrlLegalForm: string = this.env.apiUrl+this.env.legalFormsServiceSuffix;

    constructor(
        private http: HttpClient,
        @Inject(APP_ENVIRONMENT) private env: AppEnvironment,
    ){}

    formatNace(nace: string){
        let app='';
        if(nace.indexOf('.')==1) app='0'+nace;
        else app=nace+'';
        let vet=app.split('.');
        if(vet[1].length==2 && vet[1][1]=='0'){
            app=app.slice(0,app.length-1);
        }
        return app;
    }
    
    getPunctResearch(
        token: string | undefined,
        jwt: string | undefined,
        cf: string | null, 
        ragioneSociale: string | null, 
        nace: string | null, 
        cap: string | null
    ){
        let params = new HttpParams();
        let headers = new HttpHeaders();
        if(jwt) headers=headers.append('Id-Token',jwt);
        if(token) params = params.append('recaptchaToken',token);
        if(cf) params = params.append('cf',cf);
        else{
            if(ragioneSociale) params = params.append('ragioneSociale',ragioneSociale);
            if(nace) params = params.append('nace',this.formatNace(nace));
            if(cap) params = params.append('cap',cap);
        }
        return this.http.get<ResponseInfoImpresa>(this.apiUrlPunct, {params: params, headers: headers});
    }
    
    getParamResearch(
        token: string | undefined,
        jwt: string | undefined,
        cap: string | null,
        nace: string | null,
        statoImpresa: string | null,
        formaGiuridica: string | null
    ){
        let params = new HttpParams();
        let headers = new HttpHeaders();
        if(jwt) headers=headers.append('Id-Token',jwt);
        if(token) params = params.append('recaptchaToken',token);
        if(cap) params = params.append('cap',cap);
        if(nace) params = params.append('nace',this.formatNace(nace));
        if(statoImpresa) params = params.append('statoImpresa',statoImpresa);
        if(formaGiuridica) params = params.append('formaGiuridica',formaGiuridica);
        return this.http.get<ResponseInfoImpresa>(this.apiUrlParam, {params: params, headers: headers});
    }

    getLegalForms(){
        return this.http.get<ResponseLegalForm>(this.apiUrlLegalForm);
    }

}