import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './homepage/homepage.component';
import { ErrorComponent } from './error/error.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientXsrfModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ResearchPageComponent } from './research-page/research-page.component';
import { DefaultInterceptor } from './services/default.interceptor';
import { WebservicePageComponent } from './webservice-page/webservice-page.component';
import { FaqPageComponent } from './faq-page/faq-page.component';
import { SharedModule } from './shared/shared.module';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

const captchaKey = "6Lcr9wsqAAAAAIdo8d6YOUMmsaoSOcfIArULqVL4";

const translateConfig = {
  loader: {
      provide: TranslateLoader,
      useFactory: httpTranslateLoader,
      deps: [HttpClient]
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    ResearchPageComponent,
    ErrorComponent,
    WebservicePageComponent,
    FaqPageComponent,
  ],
  imports: [
    RecaptchaModule,
    RecaptchaFormsModule,
    SharedModule,
    AppRoutingModule,
    TranslateModule.forRoot(translateConfig),
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN'
    }),
  ],
  providers: [
    {
      provide: LocationStrategy, 
      useClass: HashLocationStrategy
    },
    { provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: captchaKey,
      } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}