import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { AppEnvironment } from "../model/env";
import { APP_ENVIRONMENT } from "./session.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class AcceptService{

    private apiUrl: string = this.env.apiUrl+this.env.acceptSuffix;

    constructor(
        private http: HttpClient,
        private translateService: TranslateService,
        @Inject(APP_ENVIRONMENT) private env: AppEnvironment,
    ){}

    acceptLicense(email: string, check: boolean = false){
        let params=new HttpParams();
        if(email) params=params.set('email',email);
        params=params.set('canaleDiRicerca',check ? 'PORTALE_WEB' : 'API');
        return this.http.get<any>(this.apiUrl,{params: params});
    }
    
    downloadManual(){
        const dwldLink = document.createElement("a");
        const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;

        // Safari & Opera do not support direct link clicking
        if (isSafariBrowser) {
            dwldLink.setAttribute("target", "_blank");
        }

        dwldLink.setAttribute("href", 'assets/documents/Istruzioni_di_utilizzo.pdf');
        dwldLink.setAttribute("download", this.translateService.instant('manual'));
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }
}