import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { ResearchPageComponent } from './research-page/research-page.component';
import { ErrorComponent } from './error/error.component';
import { WebservicePageComponent } from './webservice-page/webservice-page.component';
import { FaqPageComponent } from './faq-page/faq-page.component';

const routes: Routes = [
  { path: 'home', component: HomepageComponent},
  { path: 'research', component: ResearchPageComponent},
  { path: 'webservice', component: WebservicePageComponent},
  { path: 'faq', component: FaqPageComponent},
  { path: 'error', component: ErrorComponent},
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled'
    },
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
