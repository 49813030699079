import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() showSide: EventEmitter<any> = new EventEmitter<any>();
  @Output() hideSide: EventEmitter<any> = new EventEmitter<any>();

  lang: string = '';

  alive: boolean = true;

  constructor(
    public sessionService: SessionService,
    private router: Router,
  ) { }

  ngOnDestroy(): void {
    this.alive=false;
  }

  ngOnInit(): void {
    this.sessionService.$lang.pipe(takeWhile(()=>this.alive)).subscribe(res=>{
      this.lang=res+'';
    });
  }

  changeLang(e: any){
    this.sessionService.$lang.next(e);
  }

  goTo(link: string){
    this.hideSide.emit();
    this.router.navigate([link]);
  }

  onKeydown(e: any){
    if(e.keyCode==32 || e.keyCode==13){
      this.showSide.emit()
    }
  }

  cLang(e: any, l: string){
    if(e.keyCode==32 || e.keyCode==13){
      this.changeLang(l);
    }
  }

}
