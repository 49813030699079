import { AfterContentChecked, ChangeDetectorRef, Component, HostListener, InjectionToken, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter, takeWhile } from 'rxjs/operators';
import { SessionService } from './services/session.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppEnvironment } from './model/env';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentChecked, OnDestroy{
  title = 'info-psi';
  modal: any;

  alive: boolean = true;

  constructor(
    private changeDetector: ChangeDetectorRef,
    public translate: TranslateService,
    public sessionService: SessionService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.translate.addLangs(['it', 'en']);
    this.translate.setDefaultLang('it');
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any){
    let side=document.getElementById('sidebar');
    let head=document.getElementById('header');
    if(side && head){
      side.style.paddingTop=window.scrollY+head.getBoundingClientRect().height+
      Math.ceil((window.innerHeight*1)/100)+'px';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any){
    //event == when the method is called by ngOnInit
    if(event == null){
      event = {};
      event.target = {};
      event.target.innerWidth = window.innerWidth;
    }
    this.onScroll(window.scrollY);
  }

  ngAfterViewInit(): void {
    setTimeout(()=>{
      this.onScroll(window.scrollY);
    }, 500);
  }

  ngOnDestroy(): void {
    this.alive=false;
  }

  async ngOnInit() {

    this.sessionService.getCSRFtoken().toPromise().then(resp => {
      this.sessionService.setCSRFtoken().subscribe();
    });    

    // if(!window.location.pathname.includes('home')) this.router.navigate(['home']);    

    document.addEventListener('click',()=>{
      let elems=document.getElementsByClassName('dropdown-container');
      if(elems){
        for(let i=0;i<elems.length;i++){
          elems.item(i)?.parentElement?.classList.remove('show');
        }
      }
    });

    document.addEventListener('keydown',event=>{
      let elems=document.getElementsByClassName('collapse dropdown-custom show');
      if(elems.item(0)){
        if((event.code == 'ArrowDown' || event.code == 'ArrowUp')){
          event.preventDefault();
          let vet=[];
          let childs=elems.item(0)!.children.item(0)!.children;
          for(let i=0; i<childs.length; i++){
            vet.push(childs.item(i) as HTMLElement);
          }
          let ind=vet.findIndex(x=>x==document.activeElement);
          if(ind<0){
            if(event.code == 'ArrowDown') vet[0].focus();
            else vet[vet.length-1].focus();
          }
          else{
            if(event.code == 'ArrowDown'){
              if(ind==(vet.length-1)){
                vet[0].focus();
              }
              else{
                vet[ind+1].focus();
              }
            }
            else{
              if(ind==0){
                vet[vet.length-1].focus();
              }
              else{
                vet[ind-1].focus();
              }
            }
          }
        }
        else{
          // let iInt=0;
          // let int=setInterval(()=>{
            elems.item(0)!.classList.remove('show');
          //   if(!elems.item(0)!.classList.contains('show') || iInt>10) clearInterval(int);
          //   iInt++;
          // },100);
        }
      }
    });

    this.sessionService.$lang.pipe(takeWhile(()=>this.alive)).subscribe(res=>{
      this.translate.use(res.toLowerCase());
    });
      
    // this.sessionService.getIp();
    await this.sessionService.getConfigs();
    
    //@ts-ignore
    this.modal=new bootstrap.Modal(document.getElementById('acceptModal'), {
      backdrop: 'static', keyboard: false
    });
    // this.modal.show();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();    
  }

  close(check: boolean = false){
    this.modal.hide();
  }

  checkErrorPage(){
    return window.location.pathname.includes('error');
  }

  showSide(){
    let elem=document.getElementById('sidebar');
    if(elem){
      if(elem.className.includes('d-none')){
        elem.classList.remove('d-none');
      }
      else{
        elem.classList.add('d-none');
      }
    }
  }

  hideSide(){
    let elem=document.getElementById('sidebar');
    if(elem){
      elem.classList.add('d-none');
    }
  }

  goTo(link: string){
    this.showSide();
    this.router.navigate([link]);
  }

}
