import { AfterViewChecked, AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { APP_ENVIRONMENT, SessionService } from '../services/session.service';
import { AbstractControl, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { map, startWith, takeWhile } from 'rxjs/operators';
import { ToastService } from '../services/toast.service';
import { AddressInfoImpresa, InfoImpresa } from '../model/info-impresa.model';
import { LegalForm } from '../model/legal-form.model';
import { CsvService } from '../services/csv.service';
import { ResearchService } from '../services/research.service';
import { ResponseInfoImpresa } from '../model/response.model';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AppEnvironment } from '../model/env';
import { AcceptService } from '../services/accept.service';

@Component({
  selector: 'app-research-page',
  templateUrl: './research-page.component.html',
  styleUrls: ['./research-page.component.scss']
})
export class ResearchPageComponent implements OnInit, OnDestroy, AfterViewInit {
  punctualSearch: FormGroup = new FormGroup({
    cf: new FormControl('',[Validators.pattern(/(^[0-9]{11}$)|(^[a-zA-Z]{6}[0-9]{2}[a-zA-z]{1}[0-9]{2}[a-zA-Z]{1}[0-9]{3}[a-zA-Z]{1}$)/)]),
    ragioneSociale: new FormControl('',[]),
    nace: new FormControl('',[Validators.pattern(/(^[0-9]{2}\.[0-9]{1,2}$)|(^[0-9]{1,2}\.[0-9]{2}$)/)]),
    cap: new FormControl('',[Validators.pattern(/^[0-9]{5}$/)]),
  });
  paramSearch: FormGroup = new FormGroup({
    cap: new FormControl('',[Validators.pattern(/^[0-9]{5}$/)]),
    nace: new FormControl('',[Validators.pattern(/(^[0-9]{2}\.[0-9]{1,2}$)|(^[0-9]{1,2}\.[0-9]{2}$)/)]),
    statoImpresa: new FormControl(false,[]),
    formaGiuridica: new FormControl('',[Validators.minLength(1)]),
  });
  alive: boolean = true;
  step: number = 0;

  statusList: any = [
    // {label: 'emptyStatus', value: 0},
    {label: 'activeStatus', value: true, apiValue: 'REGISTRATA'},
    {label: 'inactiveStatus', value: false, apiValue: 'TUTTE'},
  ];

  legalForms: LegalForm[] = [
    // {descrizione: 'emptyLegalForm', codice: '0', translate: true},
    // {descrizione: 'form 1', codice: '1'},
    // {descrizione: 'form 2', codice: '2'},
    // {descrizione: 'form 3', codice: '3'},
  ];

  filteredOptions: Observable<any[]> = new Observable<any[]>();

  cols: string[] = ["ragioneSociale", "formaGiuridica", "codiceFiscale",
    "statoAttivita", "dataRegistrazione", "indirizzoSedeLegale", "codiceNACE",];
  
  page: number = 0;
  size: number = 5;
  totalPages: number = 2;
  totalElements: number = 8;
  sizeList: number[] = [5,10,20];
  results: {[index: string]: InfoImpresa}[] = [];
  tableHeaders: any[] = [];
  tableCols: any[] = [];
  visibleSearch: boolean = false;
  token: string|undefined;
  token2: string|undefined;

  showSearch: boolean = false;
  emailToken: FormControl = new FormControl(null,[Validators.required,Validators.email]);
  jwt: string = '';

  dataErogazione: Date = new Date();

  constructor(
    public sessionService: SessionService,
    private toastService: ToastService,
    private csvService: CsvService,
    private researchService: ResearchService,
    private translateService: TranslateService,
    private acceptService: AcceptService,
    @Inject(APP_ENVIRONMENT) public env: AppEnvironment,
  ) {
    this.token = undefined;
    this.token2 = undefined;
  }

  ngOnDestroy(): void {
    this.alive=false;
  }

  ngAfterViewInit(): void {
    this.sessionService.$lang.pipe(takeWhile(()=>this.alive)).subscribe(lang=>{
      this.tableHeaders=this.cols.map(
        k=>{return {label: k};}
      );

      this.checkLangCaptcha(lang,'recaptcha');
      this.checkLangCaptcha(lang,'recaptcha2');

    });
  }

  ngOnInit(): void {
    // window.scrollTo(0,0);
    this.displayFn = this.displayFn.bind(this);

    this.researchService.getLegalForms().pipe(takeWhile(()=>this.alive)).subscribe(lf=>{
      if(lf?.formeGiuridiche && lf.formeGiuridiche.length>0){
        this.legalForms=this.legalForms.concat(lf.formeGiuridiche);
      }
      this.filteredOptions = this.paramSearch.controls['formaGiuridica'].valueChanges.pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.descrizione),
        map(descrizione => descrizione ? this._filter(descrizione) : this.legalForms.slice())
      );
    },error=>{
      // this.toastService.showErrorToast('ERRORE',{},'errorLegalForms');
    });
  }

  checkAuto(check: boolean = true){
    return this.legalForms?.findIndex(x=>x.codice==this.paramSearch?.value?.formaGiuridica)>=0 || 
    (check && this.paramSearch?.value?.formaGiuridica==='');
  }

  displayFn(codice: string): string {
    const correspondingOption = this.legalForms?.find(option => option.codice === codice);
    return correspondingOption ? this.translateService.instant(correspondingOption.descrizione!) : '';
  }
  
  _filter(desc: string): any[] {
    const filterValue = desc.toLowerCase();
    return this.legalForms.filter(option => option!.descrizione!.toLowerCase().includes(filterValue));
  }

  async search(form: NgForm, id: string){
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }

    // this.recaptchaService.execute({action: 'research'}).then(async token=>{
      let api: Observable<ResponseInfoImpresa> = new Observable<ResponseInfoImpresa>();
      if(id.includes('punct')){
        api=this.researchService.getPunctResearch(
          this.token,
          this.jwt,
          this.punctualSearch.get('cf')?.value?.length>0 ? this.punctualSearch.get('cf')!.value : null,
          this.punctualSearch.get('ragioneSociale')?.value?.length>0 ? this.punctualSearch.get('ragioneSociale')!.value : null,
          this.punctualSearch.get('nace')?.value?.length>0 ? this.punctualSearch.get('nace')!.value : null,
          this.punctualSearch.get('cap')?.value?.length>0 ? this.punctualSearch.get('cap')!.value : null,
        );
      }
      else if(id.includes('param')){
        api=this.researchService.getParamResearch(
          this.token2,
          this.jwt,
          this.paramSearch.get('cap')?.value?.length>0 ? this.paramSearch.get('cap')!.value : null,
          this.paramSearch.get('nace')?.value?.length>0 ? this.paramSearch.get('nace')!.value : null,
          this.statusList.find((sl: any)=>sl.value==this.paramSearch.get('statoImpresa')?.value)?.apiValue,
          this.paramSearch.get('formaGiuridica')?.value?.length>0 ? this.paramSearch.get('formaGiuridica')!.value : null,
        );
      }
      await api.pipe(takeWhile(()=>this.alive)).toPromise()
      .then(res=>{
        this.dataErogazione=res.dataErogazione ? new Date(res.dataErogazione) : new Date();
        this.results=(res?.infoImprese ?? []) as {[index: string]: InfoImpresa}[];
        this.results.forEach((r: InfoImpresa)=>{
          let dApp=r.elaborationTime ? new Date(r.elaborationTime) : new Date();
          r.elaborationTime=[
            this.formatDate(dApp.getDate()+''),
            this.formatDate((dApp.getMonth()+1)+''),
            dApp.getFullYear()+''
          ].join('/');
        });
        // console.log(this.results);
      },error=>{
        // this.toastService.showErrorToast('ERRORE',{},'errorResearch')
        this.results=[];
        this.dataErogazione=new Date();
      });
  
      this.getCols();
      this.totalElements=this.results.length;
      this.getPages(this.size);
  
      this.visibleSearch=true;

      if(id.includes('punct')){
        this.token=undefined;
        this.checkLangCaptcha(this.sessionService.$lang.value,'recaptcha',true);
      }
      else if(id.includes('param')){
        this.token2=undefined;
        this.checkLangCaptcha(this.sessionService.$lang.value,'recaptcha2',true);
      }
    // });
  }

  formatDate(d: string){
    if(d.length==1) return '0'+d;
    return d;
  }

  checkFields(field: string){
    let keysPunct=Object.keys(this.punctualSearch.value);
    switch(field){
      case 'cf':
        return keysPunct.some(k=>k!=field && this.punctualSearch.get(k)?.value?.length>0);
      case 'ragioneSociale':
        return this.punctualSearch.get('cf')?.value?.length>0;
      case 'nace':
        return this.punctualSearch.get('cf')?.value?.length>0;
      case 'cap':
        return this.punctualSearch.get('cf')?.value?.length>0;
      default:
    }
    return false;
  }

  getShownResults(){
    return this.results.slice(this.page*this.size,(this.page+1)*this.size);
  }

  changePage(e: string){
    switch(e){
      case '++':
        if(this.page<this.totalPages-1) this.page=this.totalPages-1;
        break;
      case '+':
        if(this.page<this.totalPages-1) this.page++;
        break;
      case '-':
        if(this.page>0) this.page--;
        break;
      case '--':
        if(this.page>0) this.page=0;
        break;
      default:
    }
  }

  getPages(e: number){
    this.size=e;
    this.changePage('--');
    this.totalPages=Math.ceil(this.totalElements/e);
  }

  getCols(){
    this.tableCols=this.tableHeaders.map((th,i)=>{
      return {label: th.label};
    });
  }

  clearForm(id: string){
    if(id.includes('punct')){
      this.punctualSearch.patchValue({
        cf: '',
        ragioneSociale: '',
        nace: '',
        cap: '',
      });
      this.punctualSearch.updateValueAndValidity();
    }
    else if(id.includes('param')){
      this.paramSearch.patchValue({
        cap: '',
        nace: '',
        statoImpresa: 0,
        formaGiuridica: 0,
      });
      this.paramSearch.updateValueAndValidity();
    }
    let elem=document.getElementById(id);
    let children=elem?.firstElementChild?.firstElementChild?.children;
    if(children){
      for(let i=0; i<children.length; i++){
        if(children.item(i)?.firstElementChild?.localName=='label')
          children.item(i)?.firstElementChild?.setAttribute('class','');
      }
    }
  }

  setActive(id: string){
    let elem=document.getElementById(id);
    if(elem){
      elem.setAttribute('class','active');
    }
  }

  clearClass(id: string, key: string, form: FormGroup){
    let elem=document.getElementById(id);
    if(elem && (!form.get(key)?.value || form.get(key)?.value?.length==0)){
      elem.setAttribute('class','');
    }
  }

  clearClass2(id: string, form: FormControl){
    let elem=document.getElementById(id);
    if(elem && (!form.value || form.value?.length==0)){
      elem.setAttribute('class','');
    }
  }

  checkValidPunct(){
    if(this.punctualSearch.get('cf')?.value?.length>0 && this.punctualSearch.get('cf')?.valid){
      return false;
    }
    else{
      return !(this.punctualSearch.get('cap')?.value?.length>0 &&
      this.punctualSearch.get('cap')?.valid &&
      this.punctualSearch.get('ragioneSociale')?.value?.length>0 &&
      this.punctualSearch.get('nace')?.value?.length>0 &&
      this.punctualSearch.get('nace')?.valid);
    }
  }
  
  checkValidParam(){
    return !(this.paramSearch.get('cap')?.value?.length>0 &&
    this.paramSearch.get('cap')?.valid &&
    // this.paramSearch.get('statoImpresa')?.value>0 &&
    this.paramSearch.get('formaGiuridica')?.value!='0' &&
    this.paramSearch.get('nace')?.value?.length>0 &&
    this.paramSearch.get('nace')?.valid);
  }
  
  getLabel(form: FormGroup, k: string, list: any[]){
    let elem=form.get(k);
    if(elem){
      let l=list.find(l=>k=='formaGiuridica' ? l.codice==elem!.value : l.value==elem!.value);
      return l ? (k=='formaGiuridica' ? l.descrizione : l.label) : '';
    }
    return '';
  }

  checkRequired(k: string, form: FormGroup){
    return form.get(k)?.validator ? form.get(k)?.validator!({} as AbstractControl)?.required : false;
  }

  exportCsv(){
    this.csvService.downloadCsv(this.results,this.dataErogazione,'Export_research');
  }

  getRowValue(row: {[index: string]: InfoImpresa}, col: any){
    if(col.label!='indirizzoSedeLegale' && col.label!='formaGiuridica'){
      let app=row[col.label] ? (row[col.label] as string) : '';
      return app;
    }
    else if(col.label=='formaGiuridica'){
      let app=row[col.label] ? ((row[col.label] as LegalForm).descrizione as string) : '';
      return app;
    }
    else{
      let app=row[col.label] as AddressInfoImpresa;
      let vet=[];
      if(app?.toponimo && app?.via) vet.push(app.toponimo+' '+app.via);
      if(app?.numeroCivico) vet.push(app.numeroCivico);
      if(app?.comune) vet.push(app.comune);
      if(app?.provincia) vet.push(app.provincia);
      if(app?.cap) vet.push(app.cap);
      return vet.join(', ');
    }
  }

  checkLangCaptcha(lang: string, id: string, check: boolean = false){
    let i=0;
    let interval=setInterval(()=>{
      const container = document.getElementById(id);

      if(container){
      // Get GoogleCaptcha iframe
        const iframeGoogleCaptcha = container.querySelector('iframe');


        // Get language code from iframe
        const actualLang = iframeGoogleCaptcha?.getAttribute("src")?.match(/hl=(.*?)&/)?.pop();

        // For setting new language
        if ((actualLang !== lang.toLowerCase() || check) && iframeGoogleCaptcha) {
          iframeGoogleCaptcha.setAttribute("src", iframeGoogleCaptcha.getAttribute("src")!.replace(/hl=(.*?)&/, 'hl=' + lang.toLowerCase() + '&'));
        }
        
        if((iframeGoogleCaptcha) || i>20) clearInterval(interval);
        i++;
      }

    },100);
  }

  checkLang(e: any, id: string){
    if(!e) this.checkLangCaptcha(this.sessionService.$lang.value,id);
  }

  accept(){
    this.acceptService.acceptLicense(this.emailToken.value,true).pipe(takeWhile(()=>this.alive))
    .subscribe(res=>{
      this.jwt=res.jwtToken;
      this.toastService.showSuccessToast('licenseAcceptance','licenzaAccettata');
      this.showSearch=true;
      window.scroll(0,0)
    });
  }

  download(){
    this.acceptService.downloadManual();
  }

}
