<div class="container">
    <h1 class="primary-color-a8 text-center my-5" [attr.aria-label]="'section1' | translate">
        {{'section1PageTitle' | translate}}
    </h1>
    <div class="mt-5 row">
        <div class="col-12 col-lg-12" style="text-align: justify;" aria-label="Section description">
            {{'section1PageDescr' | translate}}
        </div>
    </div>   
    <div class="mb-4 row">
        <div class="col-12 col-lg-12" style="text-align: justify;" aria-label="Section description">
            {{'section1PageDescr2' | translate}}
        </div>
    </div>   
    <!-- <h5 class="primary-color-a8 text-left">
        {{'punctSearch' | translate}}
    </h5>
    <div class="ps-2 my-2 row">
        <div class="col-12 mb-2">
            <h6 class="primary-color-a8 text-left">
                {{'apiUrl' | translate}}
            </h6>
            <div class="primary-bg-c1 primary-border-color-a8 border mt-2 p-4">
                <div class="primary-color-a8 text-break" aria-label="API url">
                    {{env.apiUrl+env.punctResearchSuffix}}
                </div>
            </div>
        </div>
        <div class="col-12">
            <h6 class="primary-color-a8 text-left">
                {{'apiParams' | translate}}
            </h6>
            <ul aria-label="api params">
                <li>{{'socialReason' | translate}} + {{'naceCode' | translate}} + {{'cap' | translate}}</li>
                <li>{{'cf' | translate}}</li>
            </ul>
        </div>
    </div>
    <h5 class="primary-color-a8 text-left my-2">
        {{'paramSearch' | translate}}
    </h5>
    <div class="ps-2 my-2 row">
        <div class="col-12 mb-2">
            <h6 class="primary-color-a8 text-left">
                {{'apiUrl' | translate}}
            </h6>
            <div class="primary-bg-c1 primary-border-color-a8 border mt-2 p-4">
                <div class="primary-color-a8 text-break" aria-label="API url">
                    {{env.apiUrl+env.paramResearchSuffix}}
                </div>
            </div>
        </div>
        <div class="col-12">
            <h6 class="primary-color-a8 text-left">
                {{'apiParams' | translate}}
            </h6>
            <ul aria-label="api params">
                <li>{{'cap' | translate}} + {{'naceCode' | translate}} + {{'statusLabel' | translate}} + {{'legalForm' | translate}}</li>
            </ul>
        </div>
    </div> -->
    <div class="row m-0 p-4 primary-border-color-a8 border">
        <div class="row m-0 px-0">
            <div class="row m-0 py-4 px-0">
                <div class="col-12 d-flex justify-content-start align-items-center">
                    <div (click)="download()" class="cursor-pointer
                    d-flex justify-content-start align-items-center">
                        <div>
                            <mat-icon class="primary-color"
                            style="transform: scale(1.5);">insert_drive_file</mat-icon>
                        </div>
                        <div class="ms-3">{{'manuale' | translate}}</div>
                    </div>
                </div>
            </div>
            <div class="row m-0 pt-4 pb-3 px-0">
                <div class="col-12">
                    {{'insEmail' | translate}}
                </div>
                <div class="col-12 fst-italic">
                    {{'noteEmail' | translate}}
                </div>
            </div>
            <div class="row m-0 pt-3 pb-4 px-0">
                <div class="form-group col-12 col-lg-6">
                    <label for="emailToken" id="label-emailToken">{{'email' | translate}}*</label>
                    <input type="text" id="emailToken" [formControl]="emailToken" class="form-control" aria-labelledby="label-emailToken"
                    [ngClass]="{'is-invalid' : emailToken.invalid}"
                    (focus)="setActive('label-emailToken')" (blur)="clearClass('label-emailToken',emailToken)">
                </div>
            </div>
        </div>
        <div class="m-0 p-4 primary-border-color-a8 primary-bg-c1 border"
        style="text-align: justify;">
            <h4>
                {{'licenza' | translate}}
            </h4>
            <div class="w-100">
                {{'licenza1_1' | translate}}                
                {{'licenza1_2' | translate}}
                <span class="fst-italic">{{'licenza1_3' | translate}}</span>                
                {{'licenza1_4' | translate}}
                <button (click)="download()" class="btn btn-link btn-link-custom">{{'licenza1_5' | translate}}</button>
                {{'licenza1_6' | translate}}
                <br>                
                {{'licenza1_7' | translate}}
                <span class="fw-bold">"{{'licenza1_8' | translate}}"</span>{{'licenza1_9' | translate}}                
            </div>
            <div class="w-100 mt-2">
                {{'licenza2_1' | translate}}
                <a [href]="env.licenzaLink2" target="_blank" class="fst-italic">{{'licenza2_link' | translate}}</a>
                {{'licenza2_2' | translate}}
                <br><br>
                {{'licenza2_3' | translate}}
                <br>
                {{'licenza2_4' | translate}}
                <br><br>
                {{'licenza2_5' | translate}}<span class="fst-italic">{{'licenza2_5a' | translate}}</span>{{'licenza2_5b' | translate}}
                <br>
                {{'licenza2_6' | translate}}<span class="fst-italic">{{'licenza2_6a' | translate}}</span>{{'licenza2_6b' | translate}}
                <br>
                {{'licenza2_7' | translate}}                
            </div>
            <div class="w-100 mt-2">
                {{'licenza3_1' | translate}}                
            </div>            
        </div>
        <div class="row mx-0 mt-4 mb-0 p-0">
            <div class="col-12 d-flex justify-content-end align-items-center">
                <button class="btn btn-primary" (click)="accept()"
                [disabled]="emailToken.invalid" style="width: fit-content;">
                    {{'accept' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>
