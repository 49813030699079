import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  lang: string = '';

  alive: boolean = true;

  constructor(
    public sessionService: SessionService,
  ) { }

  ngOnDestroy(): void {
      this.alive=false;
  }

  ngOnInit(): void {
    this.sessionService.$lang.pipe(takeWhile(()=>this.alive)).subscribe(res=>{
      this.lang=res+'';
    });
  }

  onKeydown(e: any, obj: any){
    if(e.keyCode==32 || e.keyCode==13){
      obj.click();
    }
  }

}
