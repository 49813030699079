import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AppEnvironment } from "../model/env";
import { first } from "rxjs/operators";
import { ToastService } from "./toast.service";
import { v4 as uuidv4 } from 'uuid';

export const APP_ENVIRONMENT = new InjectionToken<AppEnvironment>('App Enviromnent Injection Token');

interface Configs{
    componenteMultilinguaDisponibile?: boolean;
    ricercaPuntualeDisponibile?: boolean;
    ricercaParametriDisponibile?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    langs: string[] = ['IT','EN'];
    $lang: BehaviorSubject<string> = new BehaviorSubject<string>(this.langs[0]);   
    defaultConfigs: Configs = {
        componenteMultilinguaDisponibile: false,
        ricercaPuntualeDisponibile: false,
        ricercaParametriDisponibile: false,
    }
    configs: Configs | null = null; 
    ipInfo: any = null;
    showSpinner: boolean = false;
    private sessionId: string | null = null;

    constructor(
        private http: HttpClient,
        @Inject(APP_ENVIRONMENT) public env: AppEnvironment,
        private toastService: ToastService,
    ){
        this.initSession();
    }

    getIp(){
        this.http.get<any>('https://ipinfo.io').pipe(first()).subscribe(ip=>{
            this.ipInfo=ip;
            // console.log(this.ipInfo)
        });
    }

    async getConfigs(){
        await this.http.get<any>(this.env.apiUrl+this.env.availableServiceSuffix).pipe(first())
        .toPromise().then(configs=>{
            this.configs=JSON.parse(JSON.stringify(configs.servizi));
            // console.log(this.configs)
            //this.configs={
            //    componenteMultilinguaDisponibile: true,
            //    ricercaPuntualeDisponibile: true,
            //    ricercaParametriDisponibile: true,
            //};
        },error=>{
            // this.toastService.showErrorToast('ERRORE',{},'errorConfigs');
            this.configs=JSON.parse(JSON.stringify(this.defaultConfigs));
            // this.configs={
            //     componenteMultilinguaDisponibile: true,
            //     ricercaPuntualeDisponibile: true,
            //     ricercaParametriDisponibile: true,
            // };
        });
    }

    private initSession() {
        this.sessionId = sessionStorage.getItem('sessionId');
        if (!this.sessionId) {
            this.sessionId = uuidv4();  // Usa la funzione generateUUID se non usi la libreria
            // console.log("session id",this.sessionId)
            sessionStorage.setItem('sessionId', this.sessionId!);
        }
    }
    
    getSessionId() {
        return this.sessionId;
    }
    
    getCSRFtoken() {
        return this.http.get(this.env.apiUrl + '/csrf', { withCredentials: true });
    }

    setCSRFtoken() {
        return this.http.post(this.env.apiUrl + '/csrf-setter', {}, { withCredentials: true });
    }

}